/**
 * @section Buttons
 * Styling for CSS buttons.
 */


/**
 * Primary buttons
 */
.btn {
	background-color: transparent;
	color: $color-white;
	display: inline-block;
	font-size: 2.4rem;
	font-weight: $font-light;
	text-transform: lowercase;
	line-height: 1.5;
	margin-right: 0.3125em;
	margin-bottom: 0.3125em;
	padding: 0.25em 0;
	text-decoration: none;
	white-space: normal;
	position: relative;
	
	span {
		margin-left: 1em;
		opacity: 0.6;
		transition: all 0.25s;
	}
	
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: 200px;
		height: 1px;
		background: $color-white;
		opacity: 0.8;
	}

	&:hover,
	&:active,
	&.active {
		text-decoration: none;
		
		span {
			margin-left: 2em;
			opacity: 1;
			color: $color-primary;
		}
		
	}
}


/**
 * Secondary buttons
 */
.btn-secondary {
	background-color: transparent;
	border-color: $color-black;
	color: $color-primary;

	&:hover,
	&:active,
	&.active {
		background-color: $color-primary;
		border-color: $color-primary;
		color: $color-white;
	}
}


/**
 * Active state
 */
.btn:active,
.btn.active {
	box-shadow: inset 0 0.15625em 0.25em rgba(0, 0, 0, 0.15), 0 1px 0.15625em rgba(0, 0, 0, 0.05);
	outline: 0;
}


/**
 * Disabled state
 */
.btn[disabled] {
	box-shadow: none;
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}


/**
 * Button size
 */
.btn-large {
	font-size: 1em;
	line-height: normal;
	padding: 0.6875em 0.9375em;
}


/**
 * Block-level buttons
 */
.btn-block,
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
	display: block;
	margin-right: 0;
	padding-right: 0;
	padding-left: 0;
	width: 100%;
}


/**
 * General styles
 */
.btn,
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	cursor: pointer;
	text-align: center;
	vertical-align: middle;

	/**
	 * @workaround Override default button styling
	 * @affected Webkit/Firefox
	 */
	// background-image: none;
	-webkit-appearance: none;
}


/**
 * Remove right margin on last element and inputs
 */
.btn:last-child,
input.btn {
	margin-right: 0;
}