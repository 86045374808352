/**
 * @section Code
 * Styling for code and preformatted text.
 */

 code,
 kbd,
 pre,
 samp {
	font-family: $font-monospace;
	font-size: 0.875em;
}

code {
	background-color: lighten( $color-gray-light, 7% );
	color: $color-code;
	padding: 0.25em;
	word-wrap: break-word;
}

pre {
	background-color: lighten( $color-gray-light, 6% );
	display: block;
	line-height: 1.5;
	margin-bottom: $spacing;
	overflow: auto;
	padding: 0.8125em;
	tab-size: 4;
	white-space: pre-wrap;
	word-break: break-all;

	code {
		background-color: transparent;
		border: 0;
		color: inherit;
		font-size: 1em;
		padding: 0;
	}
}