// @section Configuration Settings
// Adjust colors, font stacks, breakpoints and sizing.
//
// Typographic Scale
// (For math purposes. Actual font sizes in ems.)
// 1px, 4px, 5px, 8px, 9px, 11px, 12px, 13px, 15px, 16px, 19px, 21px, 24px, 28px, 32px, 48px, 64px, 80px, 96px
// line height: 1.5em on small screens, 1.5625em on big screens


// Colors
$color-primary: #b0222c;
$color-black: #0d0006;
$color-white: #ffffff;

$color-info: #0088cc; // Blue
$color-success: #377f31; // Green
$color-danger: #880e14; // Red
$color-warning: #dba909; // Yellow
$color-code: #dd1144;

$color-gray-dark: #808080;
$color-gray-light: #e5e5e5;
$color-gray-lighter: #f7f7f7;


$font-primary: "interstate", Arial, sans-serif;
$font-thin: 200;
$font-light: 400;
$font-regular: 500;
$font-medium: 500;
$font-bold: 700;
$font-extrabold: 700;
$font-monospace: Menlo, Monaco, "Courier New", monospace;


// Breakpoints
$bp-xsmall: 20em;
$bp-small: 30em;
$bp-medium: 40em;
$bp-large: 60em;
$bp-xlarge: 80em;


// Sizing
$font-size: 62.5%;
$spacing: 1.5625em;
$container-width: 94%;
$container-max-width: 94em;


// Grid
$grid-margins: 1.4%;
$grid-sizes: (
	// Grid width options
	// Add/remove grid's as needed
	// $name: $width
	// $name - {string} class suffix
	// $width - {string} width of the grid
	tenth: 10%,
	fifth: 20%, 
	fourth: 25%,
	third: 33.33333333333%,
	half: 50%,
	two-thirds: 66.666666666667%,
	three-fourths: 75%,
	full: 100%
);
$grid-breakpoints: (
	// Breakpoints at which to activate grid
	// Add/remove breakpoints as needed
	// ($breakpoint, $prefix-class, $include-offsets)
	// $breakpoint - {string|variable} the breakpoint
	// $prefix-class - {string|optional} class to be used with `.row` to activate grid
	// $include-offsets - {boolean} if true, include offset classes at this breakpoint
	($bp-xsmall, ".row-start-xsmall", false),
	($bp-small, ".row-start-small", false),
	($bp-medium, null, true),
);
$grid-wrap: true;