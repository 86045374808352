.preload {
	position: absolute;
	z-index: 3000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $color-black;

	.light & {
		background: $color-white;

		.ticker__text {
			color: $color-primary;

			.ticker.active {
				color: $color-black;
			}
		}

	}

	.ticker__text {
		font-size: 4rem;
		font-family: kabel, sans-serif;
		position: relative;

		.ticker {
			transition: 2s color;
		}

		.ticker__line {
			position: absolute;
			height: 1px;
			bottom: -15px;
			width: 0;
			left: 0;
			background: $color-primary;
		}

		.ticker.active {
			font-size: 0.74em;
			color: $color-primary;
		}
	}
}

.btn_grid {
	max-width: 880px;
	display: flex;

	.btn {
		display: inherit;
	}
}

.menu {

	position: absolute;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background: $color-black;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	z-index: 100;

	.light & {
		background: $color-white;
	}

	@at-root .menu__open .menu {
		visibility: visible;
	}

	.menuNavScroll {
		position: absolute;
		z-index: 2;
		height: 58vh;
		top: 50%;
		left: 0;
		padding-left: 18px;
		transform: translateY(-50%);
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		opacity: 0;

		i {
			opacity: 0.5;
		}
	}

	nav {
		position: absolute;
		z-index: 1;
		height: 50vh;
		top: 50%;
		left: 0;
		padding-left: 120px;
		padding-right: 30px;
		overflow-y: scroll;
		overflow-x: visible;
		transform: translate(0, 0);
		opacity: 0;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		&:after {
			content: '';
			position: fixed;
			bottom: -40px;
			left: 0;
			width: 100%;
			height: 250px;
			z-index: 1;
			opacity: 0;
			pointer-events: none;
			background: rgb(13, 0, 6);
			background: -moz-linear-gradient(0deg, rgba(13, 0, 6, 0.9) 25%, rgba(13, 0, 6, 0) 100%);
			background: -webkit-linear-gradient(0deg, rgba(13, 0, 6, 0.9) 25%, rgba(13, 0, 6, 0) 100%);
			background: linear-gradient(0deg, rgba(13, 0, 6, 0.9) 25%, rgba(13, 0, 6, 0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0d0006", endColorstr="#0d0006", GradientType=1);
		}

		.nav__item {
			font-size: 12rem;
			font-weight: $font-bold;
			line-height: 1.1;
			position: relative;
			margin-bottom: 80px;
			transition: 2s all;
			transition-delay: 0.8s;

			@at-root .menu__open .nav__item {
				margin-bottom: 0 !important;
			}

			&:before {
				content: '';
				position: absolute;
				height: 1px;
				width: 56px;
				background: #291f25;
				left: -120px;
				bottom: 30%;
				transition: .25s all;
			}

			a {
				color: #291f25;
				display: block;
				transition: all .7s cubic-bezier(.19, 1, .22, 1);
			}

			&.current a,
			&:hover a {
				transform: translate3d(30px, 0, 0);
				color: #b9b8b9;
			}

			&.current:before,
			&:hover:before {
				background: #b9b8b9;
			}

		}

	}

	.menu__image {
		position: absolute;
		width: 100%;
		max-width: 1121px;
		height: 100%;
		max-height: 634px;
		background: url('../images/menu-bg-1.jpg');
		background-size: cover;
		background-position: center;
		opacity: 0.7;
		bottom: 10%;
		right: -20px;

		.light & {
			filter: brightness(0.1) invert(1);
			opacity: 1;
		}

		.light &:before {
			content: '';
			background: #fff;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 1;
		}

	}

	.menu__contact {
		max-width: 214px;
		text-align: right;
		position: absolute;
		top: 50%;
		right: 10%;
		transform: translateY(-50%);
		z-index: 1;
		opacity: 0;

		p {
			font-size: 1.6rem;
			line-height: 1.9;

			span {
				font-weight: $font-bold;
			}
		}

		hr {
			width: 90%;
			margin: 0 0 0.5em;
			float: right;

			.light & {
				border-color: $color-black;
			}
		}
	}

	.menu__scroll {
		position: absolute;
		bottom: 2%;
		right: 2%;
		transform-origin: 48.8189% center;
		display: flex;
		width: 52px;
	}

}

.mobile__menu {
	opacity: 0;
	transition: .3s opacity ease-out;
	display: none;

	.mobile__menu__open & {
		opacity: 1;
	}

	nav {

		.nav__item {
			padding: 8px 0;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			font-size: 2rem;
			opacity: 0.6;

			.light & {
				opacity: 1;
				border-bottom: 1px solid rgba(13, 0, 6, 0.2);
			}

			a {
				position: relative;
			}

			&.current {
				font-weight: $font-bold;
				color: $color-primary;
			}

		}

	}
}
