/* ===================================================== */
// HELPER  CLASSES
/* ===================================================== */

.clear {
	clear: both;
}

//percentage min-height class
.percent-height:before {
  content: "";
  float: left;
  padding-top: 52%;
}

.width-holder {
  max-width: 1920px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: 0 4rem;
}

.half-width {
  width: 50%;
}

.no-border {
  border: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.full-h {
  min-height: 100vh;
  display: flex;
}
//.mb-1,.mb-2 classes
@for $i from 0 through 10 {
  .pb-#{$i} { padding-bottom: #{$i}rem; }
  .pt-#{$i} { padding-top: #{$i}rem; }
  .mb-#{$i} { margin-bottom: #{$i}rem; }
  .mt-#{$i} { margin-top: #{$i}rem; }
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 500ms;
  -webkit-transition: opacity 500ms;
}