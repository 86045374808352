.home {

	main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: url('../images/home-bg.jpg');
		background-size: cover;
		background-position: center;
	}

	.home__thoughts {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		z-index: 2;

		.thought {
			font-size: 3rem;
			opacity: 0;
			font-weight: $font-bold;
			transition: 0.75s all;
			padding: 10px;
			text-align: center;
			width: 30%;

			&:hover {
				opacity: 0.8 !important;
			}

			&.viewed {
				color: $color-primary;
				text-decoration: line-through;

				&:hover {
					opacity: 0.2 !important;
				}

				&:after {
					content: 'read';
					position: absolute;
					top: -0.4em;
					left: 10px;
					font-size: 0.6em;
				}
			}

		}

		@for $i from 1 through 8 {
			.thought:nth-child(#{$i}) {
				transform: scale(0.8) translateY((random(200) - 100) + px);
			}
		}
	}

	.home__center {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 90vh;
		padding: 0;
		position: relative;
		transform-origin: left center;

		.home__content__text {
			position: absolute;
			top: 42%;
			left: 0;
			opacity: 0;
			visibility: hidden;
			width: 100%;
			display: flex;
			justify-content: space-between;
			
			.btns {
				width: 30%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}
			
			.btn {
				display: flex;
				opacity: 0;
				visibility: hidden;
			}
		}

	}

	.text__logo {
		font-size: 10rem;
		margin-bottom: 2rem;
	}

	.text {
		font-size: 1.8rem;
		line-height: 1.5;
		letter-spacing: -0.025em;
		max-width: 800px;
		opacity: 0;
		margin-bottom: 1rem;
	}

	.btn_right {
		position: absolute;
		right: 5%;
		top: 50%;
		transform: translateY(-50%);
		text-align: right;

		span {
			opacity: 0.6;
			transition: 0.25s all;
		}

		&:before {
			content: '';
			width: 200px;
			max-width: 200px;
			height: 1px;
			position: absolute;
			top: -0.7rem;
			right: 0;
			background: $color-white;
			opacity: 0.8;
		}

		&:after {
			content: '';
			width: 30px;
			height: 1px;
			position: absolute;
			top: -0.7rem;
			right: 0;
			background: $color-white;
			transform-origin: right center;
			transform: rotate(30deg);
			opacity: 0.8;
		}

		&:hover span {
			margin-right: 2em;
			opacity: 1;
			color: $color-primary;
		}
	}

}

.statements {

	background-image: url('../images/wave-bg.png');

	.slick-arrow {
		z-index: 10;
	}

	.btn_next {
		text-align: right;
		position: relative;
		margin: 0;
		opacity: 0.3;
		transition: 0.25s all;

		span {}

		&:before {
			content: '';
			width: 200px;
			height: 1px;
			position: absolute;
			top: -0.7rem;
			right: 0;
			background: $color-white;
		}

		&:after {
			content: '';
			width: 40%;
			height: 1px;
			position: absolute;
			top: -0.7rem;
			right: 0;
			background: $color-white;
			transform-origin: right center;
			transform: rotate(30deg);
		}

		&:hover {
			opacity: 0.8;
		}

	}

	.btn_prev {
		text-align: left;
		position: relative;
		margin: 0;
		opacity: 0.3;
		transition: 0.25s all;

		span {}

		&:before {
			content: '';
			width: 200px;
			height: 1px;
			position: absolute;
			top: -0.7rem;
			left: 0;
			background: $color-white;
		}

		&:after {
			content: '';
			width: 30%;
			height: 1px;
			position: absolute;
			top: -0.7rem;
			left: 0;
			background: $color-white;
			transform-origin: left center;
			transform: rotate(-30deg);
		}

		&:hover {
			opacity: 0.8;
		}

	}

	.thought {
		position: absolute;
		left: -7rem;
		top: 6rem;
		width: 100%;

		p {
			font-size: 20rem;
			color: #23181f;
			font-weight: $font-bold;
			margin: 0;
		}

	}

	.statement__fader {
		content: '';
		position: fixed;
		bottom: -40px;
		left: 0;
		width: 100%;
		height: 250px;
		z-index: 1;
		opacity: 0;
		pointer-events: none;
		background: rgb(13, 0, 6);
		background: -moz-linear-gradient(0deg, rgba(13, 0, 6, 0.9) 25%, rgba(13, 0, 6, 0) 100%);
		background: -webkit-linear-gradient(0deg, rgba(13, 0, 6, 0.9) 25%, rgba(13, 0, 6, 0) 100%);
		background: linear-gradient(0deg, rgba(13, 0, 6, 0.9) 25%, rgba(13, 0, 6, 0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0d0006", endColorstr="#0d0006", GradientType=1);
	}

	#statementSlick {
		display: flex;
		height: 100vh;
		width: 100%;
		align-items: center;
		justify-content: center;

		.slick-list {
			height: auto;
			width: 100%;
			padding-bottom: 30px;
		}

		.slick-slide > div {
			text-align: center;
		}

	}

	.statement__container {
		display: flex;
		position: relative;
		width: auto !important;

		.statement__para {
			position: absolute;
			top: 50%;
			left: 10%;
			width: 80%;
			text-align: left;
			pointer-events: none;
			opacity: 0;
			transform: translateY(-30%);
			transition: 0.45s all;

			span {
				font-weight: $font-bold;
			}
		}

		&.open .statement__para {
			opacity: 0 !important;
		}

		.statement__text {
			font-size: 7.2rem;
			z-index: 1;

			p {
				font-weight: $font-thin;
				position: absolute;
				margin: 0;

				&:first-child {
					top: 15%;
					left: -15rem;
				}

				&:last-child {
					bottom: -5%;
					right: -6rem;
				}
			}

			span {
				font-weight: $font-regular;
			}
		}

		.statement__image__container {

			position: relative;
			z-index: -1;

			.overlay {
				opacity: 0;
				background: $color-black;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;
				z-index: 1;
			}
		}

		.statement__image {
			max-width: 1195px;
			max-height: 634px;
		}

		.statement__scroll__open {
			position: absolute;
			bottom: 15%;
			left: 10%;
			cursor: pointer;
			z-index: 1;

			svg,
			span {
				display: inline-block;
				vertical-align: middle;
			}

			svg {
				transform-origin: 48.8189% center;
				width: 52px;
			}

			span {
				font-size: 1.6rem;
				font-family: kabel, sans-serif;
				letter-spacing: 0;
				margin-left: 10px;
			}
		}

		.statement__scroll__close {
			position: absolute;
			top: 7%;
			right: 4%;
			cursor: pointer;
			z-index: 1;
			opacity: 0;

			svg,
			span {
				display: inline-block;
				vertical-align: middle;
			}

			svg {
				transform-origin: 48.8189% center;
				width: 52px;
			}

			span {
				font-size: 1.6rem;
				font-family: kabel, sans-serif;
				letter-spacing: 0;
				margin-left: 10px;
			}
		}


		.statement__scroll__bars {
			position: absolute;
			height: 100%;
			left: 7%;
			top: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 5% 0;
			z-index: 2;
			opacity: 0;

			span {
				display: block;
				height: 160px;
				width: 1px;
				padding: 0 10px;
				border-right: 1px solid #fff;
				opacity: 0.3;
				position: relative;
				cursor: pointer;
				transition: 0.25s all;

				&.up {

					&:hover {
						opacity: 1;
					}

					&:after {
						content: '';
						height: 32px;
						width: 1px;
						position: absolute;
						top: 0;
						right: 0;
						background: $color-white;
						transform-origin: top center;
						transform: rotate(30deg);
					}
				}

				&.down {

					&:hover {
						opacity: 1;
					}

					&:after {
						content: '';
						height: 32px;
						width: 1px;
						position: absolute;
						bottom: 0;
						right: 0;
						background: $color-white;
						transform-origin: bottom center;
						transform: rotate(-30deg);
					}
				}
			}
		}

		.statement__content {
			position: absolute;
			width: 80%;
			height: 100%;
			left: 10%;
			top: 10%;
			padding: 0 5%;
			opacity: 0;
			pointer-events: none;
			text-align: left;
			overflow-y: scroll;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			&.open {
				pointer-events: all;
			}

			h4 {
				font-size: 3.6rem;
				font-weight: $font-thin;
			}

			p {
				font-size: 2.2rem;
				font-weight: $font-light;
				line-height: 1.5;

				span {
					font-weight: $font-bold;
				}
			}

			/*&:after {
				content: '';
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 300px;
				z-index: 1;
				background: rgb(13,0,6);
				background: -moz-linear-gradient(0deg, rgba(13,0,6,0.9) 25%, rgba(13,0,6,0) 100%);
				background: -webkit-linear-gradient(0deg, rgba(13,0,6,0.9) 25%, rgba(13,0,6,0) 100%);
				background: linear-gradient(0deg, rgba(13,0,6,0.9) 25%, rgba(13,0,6,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0d0006",endColorstr="#0d0006",GradientType=1);
				
			}*/

		}

	}

}

.filter {

	background-image: url('../images/filters-bg.jpg');
	background-size: cover;
	background-position: center;

	.menu__scroll {
		path {
			stroke: $color-black;
		}
	}

	h1 {
		font-size: 12rem;
		font-weight: $font-bold;
		letter-spacing: -0.025em;
	}

	.content {
		padding-top: 10%;

		.lookup {
			position: relative;
			max-width: 554px;

			> i {
				position: absolute;
				top: 10px;
				left: 20px;
				font-size: 1.8rem;
			}

			label {
				position: absolute;
				margin: 0;
				top: 6px;
				opacity: 0.36;
				font-size: 1.8rem;
				left: 50px;
				pointer-events: none;
			}

			.keyword {
				width: 100%;
				border: none;
				border-bottom: 3px solid $color-primary;
				padding: 3px 50px;
				background: none;
			}

			button {
				position: absolute;
				right: 20px;
				top: 0;
				background: none;
				border: none;
				font-size: 1.8rem;
				padding: 7px 10px;
				color: $color-black;
			}

		}

		.filters {
			display: flex;
			flex-direction: column;

			.single {
				width: 50%;
				max-width: 444px;

				.dropdown {
					border-bottom: 1px solid rgba(0, 0, 0, 0.2);
					padding: 0.6rem 5px;

					i {
						color: $color-primary;
						font-size: 3rem;
						margin-right: 30px;
						transition: 0.3s all;
					}

					&.active-accordion i {
						transform: rotate(-180deg);
					}

					.number {
						font-family: kabel, sans-serif;
						font-size: 3rem;
					}

					.question {
						font-size: 3rem;
						margin-left: 20px;
					}

				}

				.options {

					overflow: hidden;
					opacity: 0;

					.inner {
						columns: 2 200px;
						padding: 1rem 0;
					}

					p {
						font-size: 1.8rem;
						font-weight: $font-regular;
						color: $color-primary;
						margin: 0 0 0.3rem;
					}
				}

			}

		}

	}

	.modalOverlay {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.75);
		text-align: center;
		z-index: 10000;
		cursor: pointer;
		visibility: hidden;

		.modalContainer {
			display: flex;
			justify-content: center;
			height: 100%;
			width: 100%;

			.modal {
				width: 88vw;
				height: 84vh;
				background-color: $color-white;
				position: absolute;
				top: 8vh;
				z-index: 12;
				-webkit-box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.06);
				-moz-box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.06);
				box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.06);
				text-align: left;
				padding: 2% 3%;

				.breadcrumbs {

					span {
						font-size: 1.8rem;
						font-weight: $font-regular;
						margin-right: 50px;
						position: relative;

						&:not(.current) {
							opacity: 0.5;
						}

						&:not(.current):after {
							content: '';
							position: absolute;
							top: 50%;
							left: calc(100% + 10px);
							width: 30px;
							height: 1px;
							background: $color-black;
						}

						&.current {
							font-weight: $font-bold;
							color: $color-primary;

							&:after {
								content: '\f077';
								font-family: 'Font Awesome 5 Pro';
								position: absolute;
								left: 50%;
								bottom: -20px;
								transform: translateX(-50%);
							}
						}

					}
				}

				h2 {
					font-size: 12rem;
					color: $color-primary;
					font-weight: $font-bold;
				}

				.modalContent {
					width: 50%;
				}
			}
		}
	}

}

.questions {

	background-image: url('../images/filters-bg.jpg');
	background-size: cover;
	background-position: center;

	.menu__scroll {
		path {
			stroke: $color-black;
		}
	}

	h1 {
		font-size: 12rem;
		font-weight: $font-bold;
		letter-spacing: -0.025em;
	}


	.breadcrumbs {

		margin: 0;
		height: 60px;
		display: inline-flex;
		align-items: center;

		span {
			font-size: 1.8rem;
			font-weight: $font-regular;
			margin-right: 50px;
			position: relative;

			&:not(.current) {
				opacity: 0.5;
			}

			&:not(.current):after {
				content: '';
				position: absolute;
				top: 50%;
				left: calc(100% + 10px);
				width: 30px;
				height: 1px;
				background: $color-black;
			}

			&.current {
				font-weight: $font-bold;
				color: $color-primary;

				&:after {
					content: '\f077';
					font-family: 'Font Awesome 5 Pro';
					position: absolute;
					left: 50%;
					bottom: -20px;
					transform: translateX(-50%);
				}
			}

		}
	}

	.content {
		padding-top: 10%;

		.questionsSection {
			display: flex;
			flex-direction: column;

			.single {
				width: 50%;
				max-width: 444px;

				.question {
					position: relative;

					.question__spinner {
						position: absolute;
						top: 50%;
						width: 50px;
						left: -80px;
						transform: translateY(-40%);
					}

					.number {
						font-family: kabel, sans-serif;
						font-size: 3rem;
					}

					.question {
						font-size: 3rem;
						margin-left: 20px;
					}

				}

				.answers {

					overflow: hidden;
					opacity: 1;

					.inner {
						columns: 2 200px;
						padding: 1rem 0;
					}

					p {
						font-size: 1.8rem;
						font-weight: $font-regular;
						color: $color-primary;
						margin: 0 0 0.3rem;
					}
				}

			}

		}

	}

	.modalOverlay {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.75);
		text-align: center;
		z-index: 10000;
		cursor: pointer;
		visibility: hidden;

		.modalContainer {
			display: flex;
			justify-content: center;
			height: 100%;
			width: 100%;

			.modal {
				width: 88vw;
				height: 84vh;
				background-color: $color-white;
				position: absolute;
				top: 8vh;
				z-index: 12;
				-webkit-box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.06);
				-moz-box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.06);
				box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.06);
				text-align: left;
				padding: 2% 3%;


				h2 {
					font-size: 12rem;
					color: $color-primary;
					font-weight: $font-bold;
				}

				.modalContent {
					width: 50%;
				}
			}
		}
	}

}
