.barba-leave-active,
.barba-enter-active {
  transition: opacity 450ms ease;
}

/* initial state */
.barba-leave {
  opacity: 1;
}

.barba-enter {
  opacity: 0;
}

/* ending state */
.barba-leave-to {
  opacity: 0;
}

.barba-enter-to {
  opacity: 1;
}

body {
	background-color: $color-white;
	min-width: 320px;
	overflow-x: hidden;
	cursor: none !important;

	* {
		box-sizing: border-box;
		cursor: none !important;
	}
	
	&.dark {
		background-color: $color-black;
	}
	
	main {
		min-height: 100vh;
		padding: 0 8%;
	}
}
