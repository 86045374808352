@media (max-width: 1400px) {

	html {
		font-size: 56.25%;
	}

	.home {
		.text__logo {
			font-size: 12rem;
		}
	}

	.menu {
		nav .nav__item {
			font-size: 10rem;
		}

		.menu__image {
			width: 60%;
			height: 50%;
		}
	}

	.statements {

		.thought {
			p {
				font-size: 16rem;
			}
		}

		.statement__container {

			.statement__scroll__bars {
				padding: 5% 0 0;

				span {
					height: 130px;
				}

			}

			.statement__scroll__open {
				left: 20%;
				bottom: 7%;
			}

			.statement__para {
				width: 60%;
				left: 20%;

				p {
					font-size: 1.8rem;
				}
			}

			.statement__text {
				font-size: 5rem;

				p {
					&:first-child {
						left: 0;
					}

					&:last-child {
						right: 0;
					}
				}
			}

			.statement__image__container {
				text-align: center;
				width: 72%;
				margin: 0 auto;

				.statement__image {
					max-width: 100%;
				}

			}

		}

	}

}

@media (max-width: 1024px) and (orientation: portrait) {

	.follower {
		display: none;
	}

	.statements {

		.btn_prev {
			position: absolute;
			bottom: 10%;
			left: 0;
		}

		.btn_next {
			position: absolute;
			bottom: 10%;
			right: 0;
		}

		.thought {
			left: 0;
			top: 14rem;
			text-align: center;

			p {
				font-size: 9rem;
				line-height: 1;
			}
		}

		.statement__container {

			.statement__image__container {
				width: 100%;
			}

			.statement__text {
				font-size: 4rem;

				p {
					position: relative;
				}
			}

			.statement__scroll__open {
				top: 5%;
				left: 5%;
				z-index: 1000;
			}

			.statement__scroll__close {
				top: auto;
				bottom: 1%;
				right: 1%;
				z-index: 1000;
			}

			.statement__scroll__bars {
				left: 0;
			}

			.statement__content {
				top: 0;
				padding: 0;
			}

		}

	}

	.filter {

		main {
			padding: 0 5%;

			.content .filters .single .options .inner {
				columns: 150px 2;
			}

		}

		h1 {
			font-size: 8rem;
		}

	}

}

@media (max-width: 1000px) {

	header {
		justify-content: space-between;
		position: relative;
	}

	.home {
		main {
			justify-content: flex-start;
		}
	}

}


@media (min-width: 421px) {

	.desktop-only {
		display: block;
	}

	.mobile-only {
		display: none !important;
	}

}

@media (max-width: 420px) {

	.desktop-only {
		display: none;
	}

	.mobile-only {
		display: block;
	}

	html {
		font-size: 50%;
	}

	.home {
		main {
			background: none;
			min-height: calc(100vh - 60px);

			.home__center {
				padding: 3rem 0 0;
			}
		}

		.text__logo {
			font-size: 6rem;
		}

		.home__text {
			margin-bottom: 4rem;
		}

		.btn_grid {
			margin-bottom: 0;

			.btn {
				margin-bottom: 2rem;
			}
		}

		main > .btn_grid .btn:last-child {
			margin-top: 6rem;
			margin-bottom: 0;
		}
	}

	.menu {
		display: none;
	}


	.statements {

		header {
			position: fixed;
			background: $color-black;
		}

		#mobileNav {
			padding-bottom: 5%;
		}

		main {
			margin-top: 60px;

			.mobile__fader {
				content: '';
				position: fixed;
				bottom: -40px;
				left: 0;
				width: 100%;
				height: 250px;
				z-index: 1;
				opacity: 1;
				pointer-events: none;
				background: rgb(13, 0, 6);
				background: -moz-linear-gradient(0deg, rgba(13, 0, 6, 0.9) 25%, rgba(13, 0, 6, 0) 100%);
				background: -webkit-linear-gradient(0deg, rgba(13, 0, 6, 0.9) 25%, rgba(13, 0, 6, 0) 100%);
				background: linear-gradient(0deg, rgba(13, 0, 6, 0.9) 25%, rgba(13, 0, 6, 0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0d0006", endColorstr="#0d0006", GradientType=1);
			}
		}

		.btn_next,
		.btn_prev {
			top: 140px;

			&:before {
				width: 80px;
			}
		}

		.thought {
			top: 7rem;
			z-index: 1000;
			pointer-events: none;

			p {
				font-size: 4rem;
				color: #fff;
			}
		}

		#statementSlick {
			align-items: flex-start;
		}

		.statement__container {

			.statement__image__container {
				.overlay {
					opacity: 0.4;
				}
			}

			.statement__para {
				position: relative;
				top: auto;
				left: 0;
				width: 100%;
				padding: 5% 1% 0;
				margin-top: 1%;
				opacity: 1;
				text-align: center;
				transform: none;

				p {
					font-size: 1.8rem;
				}
			}

			.statement__text {
				font-size: 2.4rem;
				margin-top: 5%;
			}

			.statement__scroll__open {
				display: none;
			}

			.statement__scroll__close {
				display: none;
			}

			.statement__scroll__bars {
				display: none;
			}

			.statement__content {
				position: relative;
				width: 100%;
				left: 0;
				padding: 5% 1%;
				margin-top: 5%;
				border-top: 1px solid #fff;
				opacity: 1;

				h4 {
					font-size: 3rem;
				}

				p {
					font-size: 1.8rem;
				}

				.statement__fader {
					display: none;
				}

				.spacer-200 {
					height: 100px !important;
				}

			}
		}

	}

	.filter {

		main {
			padding: 0 5%;

			h1 {
				font-size: 4rem;
			}

			.content .filters {

				.single {
					width: 100%;

					.dropdown {

						i {
							margin-right: 20px;
						}

					}

					.options .inner {
						columns: 150px 2;
					}

				}

			}

			.modalOverlay {

				.modalContainer {

					.modal {

						height: 84vh;
						top: auto;
						bottom: 3vh;
						padding-bottom: 20px;

						.close {
							position: absolute;
							right: 0;
							top: 0;
							padding: 6px 12px;
							
							i {
								font-size: 4rem;
							}
						}

						.breadcrumbs {

							span {
								display: block;
								
								&:not(.current):after {
									display: none;
								}

								&.current:after {
									content: '\f054';
									bottom: 0;
									left: -20px;
									transform: none;
								}
								
								&.current {
									margin-left: 20px;
								}
							}
						}

						h2 {
							font-size: 3rem;
						}

						.modalContent {
							width: 100%;
							max-height: 72%;
							overflow: scroll;
							padding-right: 10px;
						}

					}

				}

			}

		}

	}

}
