header {
	display: flex;
	width: 100%;
	height: 60px;
	padding: 0 20px;
	align-items: center;
	justify-content: flex-end;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

.header__link {
	display: flex;
	margin-top: 2px;
}

.header__logo {
	width: 153px;
	height: 26px;
	margin-right: 30px;
	transition: opacity 0.25s;
	
	.dark & {
		filter: brightness(0) invert(1);
		opacity: 0.2;
	}
	
	&:hover,
	.menu__open & {
		opacity: 0.8;
	}
}

.menu__handle {
	width: 50px;
	height: 26px;
	outline: none;
	border: none;
	position: relative;
	background: transparent;
	opacity: 0.7;
	
	span {
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		overflow: hidden;
		text-indent: 200%;
		transition: opacity 0.25s;
		background: #fff;
		
		@at-root .menu__handle.open span {
			opacity: 0;
		}
		
		.light & {
			background: $color-black;
		}
	}
	
	&:before,
	&:after {
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		left: 0;
		top: 50%;
		transform-origin: 50% 50%;
		transition: transform 0.25s;
		background: #fff;
		
		.light & {
			background: #0d0006;
		}
	}
	
	&:before {
		transform: translate3d(0, -10px, 0);
	}
	
	&.open:before {
		transform: rotate3d(0, 0, 1, 45deg);
	}
	
	&:after {
		transform: translate3d(0, 10px, 0);
	}
	
	&.open:after {
		transform: rotate3d(0, 0, 1, -45deg);
	}
	
}