/**
 * @section Overrides
 * Nudge and tweak alignment, spacing, and visibility.
 */


/**
 * Text sizes
 */

.text-small {
	font-size: 0.9375em;
}

.text-large {
	font-size: 1.1875em;
	line-height: 1.4;

	@media (min-width: $bp-medium) {
		font-size: 1.3125em;
	}
}


/**
 * Text colors
 */

.text-muted {
	color: $color-gray-dark;
}


/**
 * Text alignment
 */

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

@media (min-width: $bp-medium) {
	.text-right-medium {
		text-align: right;
	}
}


/**
 * Floats
 */

.float-left {
	float: left;
}

.float-center {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.float-right {
	float: right;
}


/**
 * Margins
 */

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.margin-top {
	margin-top: $spacing;
}

.margin-bottom {
	margin-bottom: $spacing;
}

.margin-bottom-small {
	margin-bottom: 0.5em;
}

.margin-bottom-large {
	margin-bottom: 2em;
}


/**
 * Padding
 */

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.padding-top {
	padding-top: $spacing;
}

.padding-top-small {
	padding-top: 0.5em;
}

.padding-top-large {
	padding-top: 2em;
}

.padding-bottom {
	padding-bottom: $spacing;
}

.padding-bottom-small {
	padding-bottom: 0.5em;
}

.padding-bottom-large {
	padding-bottom: 2em;
}


/**
 * Visibility
 */

/*
 * Hide only visually, but have it available for screen readers:
 * @link https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap; /* 1 */
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard
 * @link https://www.drupal.org/node/897638
 */
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
}

/**
 * @workaround
 * @affected IE 8/9/10
 * @link http://juicystudio.com/article/screen-readers-display-none.php
 */
[hidden] {
	display: none;
	visibility: hidden;
}


/**
 * Contain floats
 * The space content is one way to avoid an Opera bug when the `contenteditable` attribute is included anywhere else in the document.
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 */

.clearfix:before,
.clearfix:after {
	display: table;
	content: " ";
}

.clearfix:after {
	clear: both;
}