.follower {
	width: 4px;
	height: 4px;
	border-radius: 100%;
	overflow: hidden;
	position: absolute;
	left: 0;
	z-index: 30000000000000;
	top: 0;
	pointer-events: none;
	/* Very important to prevent the follower from messing up with the mouse move */
	/*   transition: opacity 0.2s ease-in-out; */

	will-change: transform;
}

.dark .follower {
	background: $color-black;
	-webkit-box-shadow: 0px 0px 0px 1.8rem rgba(255, 255, 255, 0.25);
	-moz-box-shadow: 0px 0px 0px 1.8rem rgba(255, 255, 255, 0.25);
	box-shadow: 0px 0px 0px 1.8rem rgba(255, 255, 255, 0.25);
}

.light .follower {
	background: $color-white;
	-webkit-box-shadow: 0px 0px 0px 1.8rem rgba(13, 0, 6, 0.25);
	-moz-box-shadow: 0px 0px 0px 1.8rem rgba(13, 0, 6, 0.25);
	box-shadow: 0px 0px 0px 1.8rem rgba(13, 0, 6, 0.25);
}

.follower > * {
	margin: auto;
}

#nextQ {
	opacity: 0;
	visibility: hidden;

	.number {
		font-family: kabel, sans-serif;
		font-size: 3rem;
	}

	.question {
		font-size: 3rem;
		margin-left: 20px;
	}
}
